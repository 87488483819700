import React, { useEffect, useState } from "react";
import { RingPlanItem } from "../../../types/ringPlan";
import axios from "axios";
import { Alert, Box, Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Show } from "../component";

const RingPlan = ({
  entryOverview,
  showId,
  showName,
  setShowRingPlan,
  setHeight,
  setClass,
  setHeightGrades,
  show,
}: {
  showId: number;
  showName: string;
  setShowRingPlan: (showRingPlan: boolean) => void;
  setHeight: (height: string) => void;
  setClass: (classId: number) => void;
  setHeightGrades: (heightGrades: any) => void;
  show: Show;
  entryOverview: any;
}): JSX.Element => {
  const [ringPlans, setRingPlans] = useState<{ [key: string]: RingPlanItem[] }>(
    {}
  );

  useEffect(() => {
    if (showId) {
      getRingPlans();

      const interval = setInterval(() => getRingPlans(), 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [showId]);

  const columns: GridColDef[] = [
    {
      field: "class_name",
      headerName: "Class",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="body2">{params.row.class_name}</Typography>
            {entryOverview.entries && entryOverview?.entries.length > 0 && (
              <Box>
                {entryOverview.entries.map((e: any, index: number) => (
                  <Box display="inline-flex" key={index}>
                    {e.classes?.includes(params.row.class_id) &&
                      e.height === params.row.height && (
                        <Chip color="success" label={e.dog_name} size="small" />
                      )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "height",
      headerName: "Height",
      flex: 0.3,
    },
    {
      field: "number_of_dogs",
      headerName: "Total",
      flex: 0.1,
    },
    {
      field: "number_of_dogs_run",
      headerName: "Run",
      flex: 0.1,
    },
  ];

  const getRingPlans = async () => {
    const response = await axios.get(
      `https://api.easyagility.co.uk/shows/${showId}/rings`
    );

    setRingPlans(response.data);
  };

  return (
    <Grid sx={{ textAlign: "left" }} item xs={12}>
      {Object.keys(ringPlans).map((ringName, index) => (
        <Box
          borderRadius={"1%"}
          paddingTop={1}
          paddingLeft={2}
          bgcolor={"rgb(229, 246, 253)"}
          key={index}
        >
          <Typography marginBottom={2}>
            {showName} ({ringName})
          </Typography>
          {ringPlans[ringName].length > 0 &&
            !ringPlans[ringName][0].message.match(/- -/g) && (
              <Box padding={0.5} bgcolor="green">
                <Alert sx={{ fontWeight: "bold" }} severity="info">
                  {ringPlans[ringName][0].message}
                </Alert>
              </Box>
            )}
          <Box
            sx={{
              minHeight: "50vh",
              marginBottom: 10,
              overflow: "auto",
              width: "100%",
            }}
          >
            <DataGrid
              sx={{
                ".running": {
                  backgroundColor: "#ddf7d2",
                },
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              getRowId={() => {
                return Math.random();
              }}
              onRowClick={(row) => {
                setClass(row.row.class_id);
                const heightGrades = show.classes.find(
                  (c) => c.id === row.row.class_id
                )?.height_grades;

                setHeightGrades(heightGrades);

                console.log("height: ", row.row.height);
                setHeight(row.row.height);
                setShowRingPlan(false);
              }}
              getRowClassName={(params) => {
                for (const item of ringPlans[ringName]) {
                  if (item.status === "open") {
                    if (item.class_id === params.row.class_id) {
                      return "running";
                    } else {
                      return "";
                    }
                  }
                }
                return "";
              }}
              autoHeight
              density="standard"
              rows={ringPlans[ringName]}
              columns={columns}
              hideFooter={true}
              hideFooterPagination={true}
            />
          </Box>
        </Box>
      ))}
    </Grid>
  );
};

export default RingPlan;
