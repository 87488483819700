import React from "react";
import Results from "../Results";

const LandingPage = ({ authenticated }: { authenticated: boolean }) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Results authenticated={authenticated} />
      </div>
    </div>
  );
};

export default LandingPage;
