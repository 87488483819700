import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Results from "../Results";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type Show = {
  id: number;
  name: string;
  date: string;
  venue: string;
};

const ResultsPage = ({
  authenticated,
}: {
  authenticated: boolean;
}): JSX.Element => {
  const [shows, setShows] = useState<Show[]>([]);
  const [showId, setShowId] = useState<number | undefined>(undefined);

  useEffect(() => {
    getShows();
  }, []);

  const getShows = async () => {
    const response = await axios.get(
      `https://api.easyagility.co.uk/shows/previous`
    );
    setShows(response.data);
  };

  return (
    <>
      {showId ? (
        <>
          <IconButton
            onClick={() => {
              setShowId(undefined);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <div style={{ textAlign: "center" }}>
            <Results showID={showId} authenticated={authenticated} />
          </div>
        </>
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {shows.map((show, index) => (
            <ListItem
              sx={{ cursor: "pointer" }}
              key={index}
              onClick={() => {
                setShowId(show.id);
              }}
            >
              <ListItemText
                primary={show.name}
                secondary={new Date(show.date).toLocaleDateString()}
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default ResultsPage;
